import { useEffect, useState } from "react";
import { Link } from 'react-router-dom';
import config from './../config';

function CodexRegius(props) {
	const [data, setData] = useState(null);
	const [dates, setDates] = useState(null);
	const [rangeValue, setRangeValue] = useState(null);
	const [initialized, setInitialized] = useState(false);
	const [postInitialized, setPostInitialized] = useState(false);

	const today = (new Date());

	useEffect(() => {
		if (!data) {
			fetch(config.apiRoot+'manuscript_status/?manuscript=GKS 2365 4to')
				.then(res => res.json())
				.then(json => {
					if (json.results.length > 0) {
						setData(json.results[0]);
						

						let dates = [new Date(Date.parse(json.results[0].date_from)), new Date(Date.parse(json.results[0].date_to))];

						setDates(dates);

						setRangeValue(((today.getTime()-dates[0].getTime()) / (dates[1]-dates[0].getTime()))*100)

						setTimeout(() => setInitialized(true), 3000);
						setTimeout(() => setPostInitialized(true), 5500);
					}
				});
		}
	}, []);

	const months = [
		'janúar',
		'febrúar',
		'mars',
		'apríl',
		'maí',
		'júní',
		'júlí',
		'ágúst',
		'september',
		'október',
		'nóvember',
		'desember'
	]

	const formatDate = (date) => date.getDate()+'. '+months[date.getMonth()]+(today.getFullYear() != date.getFullYear() ? ' '+date.getFullYear() : '')

	return <div className={props.className+' group cursor-pointer w-full max-w-[105px] hover:max-w-[400px] transition-all duration-500'+(!postInitialized ? ' !max-w-[400px]' : '')}>

		<div className="absolute bg-transparent -inset-6"></div>

		<div className="absolute right-0 h-[4px] w-full bg-white/20 group-hover:bg-white/40 transition-all duration-300 rounded"></div>

		{
			rangeValue && data.status == 'on' && <div className={'absolute h-[6px] top-[-1px] right-0 rounded bg-sam_accent/60 group-hover:bg-sam_accent transition-all duration-700'+(initialized ? ' opacity-100' : ' opacity-0')} style={{
				width: (100-rangeValue)+'%'
			}} />
		}

		{
			rangeValue && <div className={'absolute top-[-5px] ml-[-3px] w-[14px] h-[14px] rounded-lg bg-white transition-[opacity,left] duration-700'+(initialized ? ' animate-pingslow opacity-40' : ' opacity-0')} style={{
				left: initialized ? rangeValue+'%' : '0%'
			}} />
		}

		{
			rangeValue && <div className="absolute top-[-2px] w-[8px] h-[8px] rounded bg-white transition-[opacity,left] duration-700" style={{
				left: initialized ? rangeValue+'%' : '0%'
			}} />
		}

		{
			dates && <div className="bg-transparent py-4 text-nowrap absolute right-0 top-0 text-lg text-right text-white opacity-0 transition-all duration-500 -translate-y-5 group-hover:translate-y-0 group-hover:opacity-100">
				{
					data.status == 'on' && <>{window.l('Konungsbók eddukvæða er til sýnis frá')} {formatDate(dates[0])} {window.l('til')} {formatDate(dates[1])}.<br/><Link className="link" to={'/handrit/GKS-2365-4to'}>{window.l('Sjá nánar.')}</Link></>
				}

				{
					data.status == 'off' && <>{window.l('Konungsbók eddukvæða verður aftur til sýnis frá')} {formatDate(dates[1])}.<br/><Link className="link" to={'/handrit/GKS-2365-4to'}>{window.l('Sjá nánar.')}</Link></>
				}
			</div>
		}

	</div>
}

export default CodexRegius;