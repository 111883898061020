import { NavLink, useLocation, Link } from 'react-router-dom';
import _ from 'underscore';
import config from '../config';

function ExhibitionPageHeader(props) {
	const location = useLocation();

	const urlBits = (url) => _.compact(url.split('/'));

 	return <>
		<Link className={'hover:no-underline'} to={'/'+config.exhibitionPageRoot+'/'+props.chapter.path}>
			<h1>{props.title}</h1>
			{
				props.subTitle && <div className="subtitle">{props.subTitle}</div>
			}
		</Link>

		<div className={'transition-all duration-[800ms] lg:max-h-[300px] pt-16  '+(urlBits(location.pathname).length > 2 ? ' opacity-0 !max-h-0 !pt-0 pointer-events-none' : '')}><p dangerouslySetInnerHTML={{__html: props.headerText}} /></div>

		{
			props.headerLinks && <div className="page-header-buttons">
			{
				props.headerLinks.map((item, index) => {
					return <NavLink key={index} className={'tab'+([config.exhibitionPageRoot, props.chapter.path, item.url].join('/').split('/').slice(0, 3).join('/') == urlBits(location.pathname).slice(0, 3).join('/') ? ' active' : '')} to={item.url}>{item['title_'+props.currentLang]}</NavLink>
				})
			}
		</div>
		}
	</>
}

export default ExhibitionPageHeader;