import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import OpeningHours from './OpeningHours';
import CodexRegius from './CodexRegius';
import config from './../config';

function FrontPage(props) {
	const [initialized, setInitialized] = useState(false);
	const [showCodex, setShowCodex] = useState(false);
	const [videoFile, setVideoFile] = useState(null);

	useEffect(() => {
		setTimeout(() => {
			setInitialized(props.initialized);
		}, 600);

		setTimeout(() => {
			setShowCodex(true);
		}, 2100);

		setVideoFile(videos[Math.floor(Math.random()*videos.length)]);

	}, [props.initialized]);

	const videos = [
		'gks02-1005-4',
		'gks02-1005-5',
		'gks_3269a_4to_19r',
		'sam_66_6',
		'am02-0105-4',
		'njals_saga_am02-0162_b_delta_1',
		'pappir_sam_66_3',
		'skinn_am02-0132-6',
		'skinn_gks04-1812-b'
	]

	return <div className="min-h-[1800px]">
		<div className={'front-wrapper h-[100vh] relative inline-block w-full transition-all duration-[600ms] '+(props.isSubPage ? ' max-h-[450px] duration-[600ms]' : ' max-h-[1200px]')+(initialized ? ' opacity-100' : 'opacity-0')}>

			<div className={'title-wrapper container relative mx-auto mb-20 md:md-20 mt-10 md:mt-40'}>

				<Link to={'/'} className={'block hover:no-underline inner-container transition-all z-10 '+(props.largeHeader && !props.isSubPage ? 'pt-1 md:pt-20 lg:pt-[10vh]' : '')+' title text-white transition-[opacity,transform] delay-[300ms] duration-[400ms] '+(initialized ? 'translate-y-0 opacity-100' : '-translate-y-6 opacity-0')}>
					<h1 className={'transition-all delay-[300ms] duration-[400ms] uppercase font-medium '+(props.largeHeader && !props.isSubPage ? 'text-4xl md:leading-[3.5rem] md:text-6xl 2xl:leading-[5rem] 2xl:text-[5rem]' : 'text-2xl lg:text-4xl')}>{window.l('Heimur í')}<br/>{window.l('orðum')}</h1>
					<div className={'transition-all delay-[300ms] duration-[400ms] subtitle '+(props.largeHeader && !props.isSubPage ? 'text-2xl md:text-4xl 2xl:text-6xl' : 'text-xl lg:text-xl')}><span className="text-white">{window.l('Handritasýning í')}</span>{window.l(' Eddu')}</div>					
				</Link>

				{
					props.isFront && <>
						<CodexRegius currentLang={props.currentLang} className={'hidden md:block absolute top-[125px] ml-10 right-10 md:right-40 z-10 transition-[opacity,transform] duration-[800ms] '+(showCodex ? 'translate-y-0 opacity-100' : 'delay-[1100ms] -translate-y-6 opacity-0')} />

						<OpeningHours currentLang={props.currentLang} className={'hidden md:block absolute top-0 right-10 md:right-40 z-10 transition-[opacity,transform] delay-[1000ms] duration-[800ms] '+(initialized ? 'translate-y-0 opacity-100' : '-translate-y-6 opacity-0')} />
					</>
				}

			</div>

			<div className={'video-wrapper absolute top-0 left-0 right-0 h-[100vh] overflow-hidden'+(props.isSubPage ? ' max-h-[450px]' : ' max-h-[1200px] min-h-[750px]')+(props.tiltMode ? ' tilt-mode-'+props.tiltMode : '')}>
				<div className="absolute w-full h-full bg-sam_dark/50"></div>

				{
					videoFile && <video autoPlay={true} muted={true} loop={true} src={config.videoRoot+videoFile+'.mp4'} className="w-full h-full object-cover" style={{
						backgroundImage: 'url(/img/video_fallback.png)',
						backgroundSize: 'cover',
						backgroundPosition: 'center'
					}}>
						<source src={config.videoRoot+videoFile+'.webm'} type="video/webm" />
						<source src={config.videoRoot+videoFile+'.mp4'} type="video/mp4" />
						<img src="/img/video_fallback.jpg" />
					</video>
				}

			</div>

			{
				props.headerContent
			}

		</div>

		<div className="container mx-auto text-sam_darkblue relative">
			<div className={'mb-40 transition-all duration-[600ms]'+(!props.showContent ? ' max-h-[0px] opacity-0 overflow-hidden' : ' max-h-[5000px] opacity-100')}>
				{
					props.children
				}
			</div>
		</div>
		
	</div>
}

export default FrontPage;