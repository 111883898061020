import { useRef } from "react";
import PlayButton from "./PlayButton";

export default function AudioPlayer(props) {
	const player = useRef(null);

return <div className="flex gap-6">
		<PlayButton audioUrl={props.src} itemTitle={props.info} itemId={props.src} />
		<div className="max-w-prose text-lg" dangerouslySetInnerHTML={{__html: props.text}} />
	</div>
}
