import unescoLogo from '../img/unesco-normal.png';
import logo from './../img/arnastofnun-logo-green.png';
import logoWhite from './../img/arnastofnun-logo.png';

function Footer(props) {
	return <div className="footer relative">
		<div className="container relative mx-auto mt-10">
			<div className="inner-container grid grid-cols-2 gap">
				<div className="text-content">
					<p>
						Stofnun Árna Magnússonar í íslenskum fræðum<br/>
						Eddu<br/>
						Arngrímsgötu 5<br/>
						107 Reykjavík
					</p>
				</div>
				<div className="text-content">
					<a href="https://arnastofnun.is"><img src={props.whiteLogo ? logoWhite : logo} className="my-10 w-[200px]" /></a>
					<img src={unescoLogo} className="ml-4 my-10 w-[170px]" />
				</div>
			</div>
		</div>
	</div>
}

export default Footer;