import { useNavigate } from "react-router-dom";

export default function Text(props) {
	let navigate = useNavigate()

	let isExternal = (url) => {
		var match = url.match(/^([^:\/?#]+:)?(?:\/\/([^\/?#]*))?([^?#]+)?(\?[^#]*)?(#.*)?/);
		if (typeof match[1] === "string" && match[1].length > 0 && match[1].toLowerCase() !== window.location.protocol) return true;
		if (typeof match[2] === "string" && match[2].length > 0 && match[2].replace(new RegExp(":("+{"http:":80,"https:":443}[window.location.protocol]+")?$"), "") !== window.location.host) return true;
		return false;
	}

	let htmlContentClickHandler = (event) => {
		let linkEl = event.target.closest('a');

		if (linkEl) {
			let linkUrl = linkEl.getAttribute('href');

			if (linkUrl) {
				if (!isExternal(linkUrl) && (!linkEl.getAttribute('target'))) {
					event.preventDefault();

					navigate(linkUrl);
				}
			}
		}
	}

	let htmlContentMoveHandler = (event) => {
		let linkEl = event.target.closest('a');

		if (linkEl) {
			if (linkEl.dataset && linkEl.dataset.marker) {
				if (props.onMarkerDataHover) {
					props.onMarkerDataHover(linkEl.dataset.marker);
				}
			}
			if (linkEl.dataset && linkEl.dataset.hotspot) {
				if (props.onHotspotDataHover) {
					props.onHotspotDataHover(linkEl.dataset.hotspot);
				}
			}
		}
		else {
			if (props.onMarkerDataHover) {
				props.onMarkerDataHover(null);
			}
			if (props.onHotspotDataHover) {
				props.onHotspotDataHover(null);
			}
		}
	}

	return <div className={props.className} dangerouslySetInnerHTML={{__html: props.content}} onClick={htmlContentClickHandler} onMouseMove={(htmlContentMoveHandler)} />
}

