import { useEffect, useState } from "react";
import { json, Link, useLocation } from 'react-router-dom';
import config from '../config';
import PageContent from "./elements/PageContent";

function Page(props) {
	const location = useLocation();
	const [pageData, setPageData] = useState(null);

	useEffect(() => {
		fetch(config.apiRoot+'pages/?path='+location.pathname)
			.then(res => res.json())
			.then(json => {
				if (json && json.results && json.results.length > 0) {
					setPageData(json.results[0])
				}
			})
	}, [location]);

	return <div className="mt-12 page-content">
		{
			pageData && <PageContent currentLang={props.currentLang} content={pageData.page_content} />
		}
	</div>
}

export default Page;