import { Fragment, useEffect } from "react";
import { Routes, Route, useOutletContext, NavLink, Navigate, useLocation, useNavigate } from "react-router-dom";

function ExhibitionSubpageView(props) {
	const { setManuscriptImage } = useOutletContext();
	const { setSubpageHeaderBackground } = useOutletContext();
	const location = useLocation();
	const navigate = useNavigate();

	useEffect(() => setManuscriptImage(props.manuscriptImage || null), [props.manuscriptImage]);
	useEffect(() => setSubpageHeaderBackground(props.headerBackground || null), [props.headerBackground]);

	useEffect(() => {
		if (props.subPages && props.subPages.length > 0 && location.pathname.endsWith(props.pagePath)) {
			setTimeout(() => navigate(props.subPages[0].path, { replace: true }), 200);
		}
	}, [props.subPages]);

	return <>
		{
			props.subPages && props.subPages.length > 1 && <Fragment>
				<h2 className="pagetitle">{props.subPageTitle}</h2>
				<p className="!max-w-full" dangerouslySetInnerHTML={{__html: props.subPageInfo}} />
			</Fragment>
		}
		{
			props.subPages && props.subPages.length > 0 && <div className="mt-10 mb-20">
				{
					props.subPages.map((item, index) => <NavLink key={index} to={item.path} className="tab">{item.title}</NavLink>)
				}
			</div>
		}

		<Routes>
			<Route path="" element={props.children} />
			{				
				props.subPages && props.subPages.length > 0 && props.subPages.map((item, index) => <Route key={index} path={item.path} element={item.content} />)
			}
		</Routes>
	</>;
}

export default ExhibitionSubpageView;