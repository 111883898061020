import { useEffect, useState, useRef } from "react";
import { Link, useLocation } from "react-router-dom";
import config from "../config";

function Plinth(props) {
	const [hover, setHover] = useState(false);
	const [visible, setVisible] = useState(false);
	const [initialized, setInitialized] = useState(false);
	const location = useLocation();

	useEffect(() => {
		setTimeout(() => {
			setVisible(true);

			setTimeout(() => setInitialized(true), (props.index*100));
		}, 0+(props.index*100))
	}, []);

	const lineStyle = {
		fill: 'none',
		stroke: '#ffffff',
		strokeWidth: 1.6,
		strokeOpacity: 1,
		strokeMiterlimit: 10,
		strokeDasharray: '3,0'
	};

	const navigationLine = (lineData) => <path transform="translate(25, 25)" d={lineData} style={lineStyle} />

	return <>
		{
			props.item.lineBefore && !props.hideLines && !props.small && navigationLine(props.item.lineBefore)
		}
		<g style={props.windowPos && !props.small ? {
				transform: 'translate('+((props.distanceFromCenter().x/props.item.distanceFromX)+20)+'px, '+((props.distanceFromCenter().y/props.item.distanceFromX)+20)+'px)'
			} : null
		}>
			<g style={!props.small ? (
					visible ?
					{
						opacity: 1,
						transitionDelay: 100+(props.index*100)+'ms',
						transition: 'opacity 800ms ease-in-out'
					} :
					{
						opacity: 0,
						transitionDelay: 100+(props.index*100)+'ms',
						transition: 'opacity 800ms ease-in-out'
					}
				) : null
			}
			>
				<path id={'p'+props.index} 
					fill={props.small ? (location.pathname.indexOf(props.item.path) > -1 ? '#ffffff' : '#ffffff') : 'url(#img'+props.index+')'}
					d={props.item.shapePath} />

				<path id={'ph'+props.index} 
					fill={props.item.fillColor} 
					stroke={props.small && location.pathname.indexOf(props.item.path) > -1 ? '#ffffff' : null}
					strokeWidth={props.small && location.pathname.indexOf(props.item.path) > -1 ? 10 : 0}
					style={{
						transition: 'all 300ms ease-in-out'
					}}
					opacity={props.small ? 1 : hover ? 0.2 : 0.7}
					d={props.item.shapePath}
					onMouseEnter={(event) => {
						if (!props.small) {
							setHover(true);
						}

						props.plinthMouseEnter(event, props.item);
					}}
					onMouseLeave={(event) => {
						if (!props.small) {
							setHover(false);
						}

						props.plinthMouseLeave(event, props.item);
					}}
					onClick={(event) => props.plinthClick(event, props.item)} />

				{
					!props.small && <path id={'e'+props.index} 
						fill={props.item.color} 
						style={{
							//transition: 'all 300ms ease-in-out',
							//transform: hover ? 'scale(1.02)' : 'scale(1)'
						}}
						d={props.item.edgePath} />
				}
			</g>
		</g>
		{
			props.item.lineAfter && !props.hideLines && !props.small && navigationLine(props.item.lineAfter)
		}
	</>
}

function PlinthMenu(props) {
	const [windowPos, setWindowPos] = useState(null);
	const [lightIntensity, setLightIntensity] = useState(0);
	const [hoverText, setHoverText] = useState('');
	const [hoverVisible, setHoverVisible] = useState(false);

	useEffect(() => {
	}, []);

	const svg = useRef();

	const svgData = [
		{
			shapePath: 'M184.465,151.488L17.027,240.736v75.352l132.6-51.572l29.75,5.969l58.186-90.91l2.193-3.427 L184.465,151.488L184.465,151.488L184.465,151.488z',
			edgePath: 'M184.64,164.445l50.699,18.594l4.417-6.89l-55.291-24.661L17.027,240.736v75.352l14.446-65.31L184.64,164.445z',
			_shapePath: 'M188.5 145.6 17.4 236.8 17.4 313.8 152.9 261.1 183.3 267.2 245 170.8 188.5 145.6z',
			_edgePath: 'M188.6 149.1 243.3 173.5 245 170.8 188.5 145.6 17.4 236.8 17.4 313.8 20.4 312.6 20.4 238.3 188.6 149.1z',
			image: 'plinth1a.jpg',
			imageWith: 270,
			imageHeight: 200,
			patternOffsetX: 285,
			patternOffsetY: 140,
			distanceFromX: 1.1,
			color: '#352f37',
			fillColor: '#352f37',
			path: '/arni-og-handritin',
			label_is: 'Árni og handritin',
			label_en: 'Introduction'
		},
		{
			shapePath: 'M246.9,180.161l-42.08,64.881l68.502,129.468l28.183,9.688l55.193-152.27L246.9,180.161 L246.9,180.161z',
			edgePath: 'M356.699,231.929L246.9,180.161l-4.081,6.265l83.988,55.019l-36.12,139.015l10.818,3.738 C301.505,384.296,356.699,231.929,356.699,231.929L356.699,231.929L356.699,231.929z',
			_shapePath: 'M252.3,174.9l-43,66.3,70,132.3,28.8,9.9,56.4-155.6-112.2-52.9Z',
			_edgePath: 'M364.5,227.8l-112.2-52.9-1.4,2.2,110.9,52.3-55.9,153.2,2.2.7c0,.1,56.4-155.5,56.4-155.5h0Z',
			lineAfter: 'M302,176c-1-.6-2.1-1.3-3.1-1.9-53-31.3-198.1,40.9-232,28.8-24.6-8.8-32.2-30-34.6-40.9',
			lineBefore: 'M376,305.5c2.4-46.7-27.8-100.7-74-129.5',
			image: 'plinth2a.jpg',
			imageWith: 205,
			imageHeight: 250,
			patternOffsetX: 180,
			patternOffsetY: 150,
			distanceFromX: 1.4,
			color: '#2f403e',
			fillColor: '#2f403e',
			path: '/upphafid',
			label_is: 'Upphaf heimsins',
			label_en: 'Beginning of the Wordl'
		},
		{
			shapePath: 'M268.135,384.199l-43.743-80.44l-97.077,13.7L97.664,523.16l32.587,48.93l50.887,6.066 l86.997-193.859l0,0V384.199z',
			edgePath: 'M112.64,516.945l17.024-199.095l-2.349-0.49L97.664,523.062l32.587,48.93l50.887,6.066l3.002-7.613l-43.5-13 L112.64,516.945z',
			_shapePath: 'M274,383.4l-44.7-82.2-99.2,14-30.3,210.2,33.3,50,52,6.2,88.9-198.1h0Z',
			_edgePath: 'M102.5,524.2l30-208.6-2.4-.5-30.3,210.2,33.3,50,52,6.2,1-2.2-51.3-6.1-32.3-49h0Z',
			lineAfter: 'M263.5,356c50.5,9.9,96,19,110.7-35.8,1-4.8,1.6-9.7,1.9-14.6',
			lineBefore: 'M84.7,480.3c2.1-27,13.3-59,37.8-93.5,34.9-51.5,90.4-40.8,141-30.9',
			image: 'plinth3a.jpg',
			imageWith: 180,
			imageHeight: 290,
			patternOffsetX: 100,
			patternOffsetY: 10,
			distanceFromX: 1.01,
			color: '#ad464f',
			fillColor: '#97595e',
			path: '/yst-sem-innst',
			label_is: 'Yst sem innst',
			label_en: 'Inside Out'
		},
		{
			shapePath: 'M257.762,430.486l-66.74,150.215l37.187,64l124.576,6.949l18.299-82.105l-35.131-109.799 L257.762,430.486L257.762,430.486L257.762,430.486z',
			edgePath: 'M191.022,580.701l37.187,64l124.576,6.949l1.355-8.705l-120.5-16l-39-54L191.022,580.701z',
			_shapePath: 'M263.4,430.7l-68.2,153.5,38,65.4,127.3,7.1,18.7-83.9-35.9-112.2-79.9-29.9h0Z',
			_edgePath: 'M196.8,580.7l-1.6,3.5,38,65.4,127.3,7.1.6-2.5-126.3-7-38-66.5h0Z',
			lineAfter: 'M215.1,552.3c-32.8,14.9-60.5,19.1-90.6,8.3-24.8-8.9-43.1-38.7-39.9-80.3',
			image: 'plinth4a.jpg',
			imageWith: 180,
			imageHeight: 230,
			patternOffsetX: 30,
			patternOffsetY: -25,
			distanceFromX: 2.9,
			color: '#6a8666',
			fillColor: '#6a8666',
			path: '/ut-og-sudur',
			label_is: 'Út og suður',
			label_en: 'Out and About'
		},
		{
			shapePath: 'M452.111,496.345l-48.93-41.98L360.71,653.312l158.24-53.627l-33.664-109.211L452.111,496.345 L452.111,496.345L452.111,496.345z',
			edgePath: 'M518.951,599.685l-33.664-109.211l6.854,98.471l-129,54.5l-2.43,9.867l158.238-53.627l0,0H518.951z',
			_shapePath: 'M462 498 412 455.1 368.6 658.4 530.3 603.6 495.9 492 462 498z',
			_edgePath: 'M530.3 603.6 495.9 492 493.2 492.5 526.9 601.7 369.3 655.1 368.6 658.4 530.3 603.6z',
			lineBefore: 'M528.7,534.5c4,18.1,1.2,38-13.6,62-38.4,62.4-106.7,37.5-139.7,3.8',
			lineAfter: 'M375.4,600.3c-6.5-6.7-11.7-13.7-14.9-20.5-19.7-40.9-56.6-77.3-107.6-47.5-13.5,7.9-26,14.6-37.8,20',
			image: 'plinth5a.jpg',
			imageWith: 190,
			imageHeight: 260,
			patternOffsetX: -10,
			patternOffsetY: 180,
			distanceFromX: 2,
			color: '#4482ac',
			fillColor: '#5582a1',
			path: '/ther-og-ver',
			label_is: 'Þér og vér',
			label_en: 'We and Thou'
		},
		{
			shapePath: 'M410.13,326.266l-25.248,104.22l68.697,57.934l81.027-14.287l-49.418-147.377l-75.156-0.489 H410.13z',
			edgePath: 'M534.705,474.132l-49.418-147.377l-11.262-0.102l36.115,136.292l-56.463,25.475L534.705,474.132 L534.705,474.132L534.705,474.132z',
			_shapePath: 'M419.1,324.2l-25.8,106.5,70.2,59.2,82.8-14.6-50.5-150.6-76.8-.5h.1Z',
			_edgePath: 'M546.4,475.3l-50.5-150.6h-2.4l49.9,148.7-82.1,14.5,2.3,2,82.8-14.6h0Z',
			lineBefore: 'M464.2,319.7c-4.7,11.6-7.#4482ac9,26.2-9.2,45.4-5.6,79.9,62.3,117,73.7,169.4',
			image: 'plinth6a.jpg',
			imageWith: 220,
			imageHeight: 280,
			patternOffsetX: 105,
			patternOffsetY: -10,
			distanceFromX: 1.09,
			color: '#efa91e',
			fillColor: '#cd9e41',
			path: '/endalokin',
			label_is: 'Endalok heimsins',
			label_en: 'End of the World'
		},
		/*
		{
			shapePath: 'M483.5 222.4 432.8 316.1 508.5 317.1 548.2 242.6 529.2 223.6 483.5 222.4Z',
			edgePath: 'M528.1 226 547 244.9 548.2 242.6 529.2 223.6 483.5 222.4 432.8 316.1 435.9 316.1 485.4 224.7 528.1 226Z',
			lineAfter: 'M576.2,235.3c-48.6,41.7-92.9,37.6-112,84.4',
			image: 'plinth7.jpg',
			imageWith: 190,
			imageHeight: 180,
			patternOffsetX: 35,
			patternOffsetY: 25,
			distanceFromX: 2.09,
			color: '#ffffff',
			fillColor: '#000000',
			path: '/tungumalid',
			label_is: 'Þróun tungumálsins',
			label_en: 'Development of the Language'
		},
		{
			shapePath: 'M585 279.5 553.9 248.3 516.1 318.1 535.3 373.7 561.5 373.2 582.3 351 585 279.5Z',
			edgePath: 'M553.9 248.3 552.7 250.5 582.7 280.6 580 350.5 558.6 373.3 561.5 373.2 582.3 351 585 279.5 553.9 248.3Z',
			image: 'plinth8.jpg',
			imageWith: 170,
			imageHeight: 280,
			patternOffsetX: -45,
			patternOffsetY: -50,
			distanceFromX: 2.39,
			color: '#ffffff',
			fillColor: '#000000',
			path: '/vidtakan',
			label_is: 'Viðtökusagan',
			label_en: 'Legacy'
		}
			*/
	]

	useEffect(() => setWindowPos(props.windowPos), [props.windowPos]);

	const distanceFromCenter = () => {
		let svgPos = svg.current.getBoundingClientRect();

		
		let svgMouseX = windowPos.x - svgPos.x;
		let svgMouseY = windowPos.y - svgPos.y;

		let svgCenterX = svgPos.width/2;
		let svgCenterY = svgPos.height/2;

		//svgMouseX = 0;
		//svgMouseY = 0;

		return {
			x: (svgMouseX-svgCenterX)/150,
			y: (svgMouseY-svgCenterY)/150
		}
	}

	return <div className={(!props.small ? 'container relative ' : '')+'text-white mx-auto mt-10 z-10 relative '+props.className}>
		<div className={!props.small ? 'inner-container' : ''}>

			{
				!props.small && <div className={'fixed text-center w-[160px] font-medium text-4xl drop-shadow-2xl transition transition-opacity duration-500 ease-in-out pointer-events-none uppercase z-20 '+(hoverVisible ? 'opacity-100' : 'opacity-0')} style={
					windowPos ? { top: windowPos.y+30, left: windowPos.x-80 } : null
				}>{hoverText.split(' ').map((word, index) => <span key={index}>{word}<br/></span>)}</div>
			}

			<div className="block md:hidden">
				{
					!props.small && svgData.map((item, index) => <Link to={'/syning'+item.path} className="rounded overflow-hidden group hover:no-underline hover:scale-105 transition-transform block relative px-6 py-8 mb-4 bg-cover bg-center" style={{
						backgroundImage: 'url(/img/'+item.image+')',
						borderColor: item.color
					}}>
						<div className="absolute inset-0 bg-black/60 transition-all group-hover:bg-black/30" />
						<div className="absolute left-0 right-0 bottom-[-18px] h-[40px] -skew-y-3" style={{
							backgroundColor: item.color
						}}/>
						<div className="absolute top-0 bottom-0 right-[-8px] w-[40px] -skew-x-[20deg]" style={{
							backgroundColor: item.color
						}}/>
						<div className="relative text-3xl text-white group-hover:no-underline">
							{item['label_'+props.currentLang]}
						</div>
					</Link>)
				}
			</div>
	
			<div className={'plinth-wrapper relative mx-[-50px]'+(!props.small ? ' hidden md:block' : '')}>
				<svg id="svg" ref={svg} xmlns="http://www.w3.org/2000/svg" 
					viewBox={'0 0 608.3 841.9'} 
					onMouseEnter={() => setLightIntensity(40)} 
					onMouseLeave={() => setLightIntensity(10)}
					transform={props.small ? 'scale(0.7)' : 'translate(0, -420)'}
				>
					{
						!props.small &&
						<defs>
							{
								svgData.map((item, index) => <pattern key={index} 
									id={'img'+index} 
									patternUnits="userSpaceOnUse" 
									width={item.imageWith || 300} 
									height={item.imageHeight || 300}
									x={item.patternOffsetX}
									y={item.patternOffsetY}
								>
									<image href={'/img/'+item.image} 
										width={item.imageWith || 300} />
								</pattern>)
							}
						</defs>

					}

					{
						!props.small && true == false &&
						<g transform="translate(25, 25)">
							<path style={{
								fill: 'none',
								stroke: '#ffffff',
								strokeWidth: 1.6,
								strokeOpacity: 0.8,
								strokeMiterlimit:10
							}} d="M559,234s5.6,1.5,17.8.6c-3.4,8.5-2,10-1.6,11.7"/>
						</g>
					}

					<g>
						{
							svgData.map((item, index) => <Plinth item={item} 
								small={props.small}
								key={index} 
								index={index} 
								hideLines={true}
								distanceFromCenter={distanceFromCenter} 
								windowPos={windowPos}
								plinthMouseEnter={(event, item) => {
									setHoverText(item['label_'+props.currentLang]);
									setHoverVisible(true);

									if (props.plinthMouseEnter) {
										props.plinthMouseEnter(event, item);
									}

									setLightIntensity(100);
								}}
								plinthMouseLeave={(event, item) => {
									setHoverVisible(false);

									if (props.plinthMouseLeave) {
										props.plinthMouseLeave(event, item);
									}

									setLightIntensity(40);
								}}
								plinthClick={(event, item) => {
									if (props.plinthClick) {
										props.plinthClick(event, item);
									}
								}} 
							/>)
						}
					</g>
				</svg>
			</div>
	
			{
				!props.small &&
				<div className={'fixed top-0 left-0 backlight transition transition-[opacity,transform] duration-1000 ease-in-out '+(lightIntensity == 100 ? 'opacity-100 scale-150' : lightIntensity == 40 ? 'opacity-60 scale-125' : 'opacity-20')} style={
					windowPos ? { top: windowPos.y-300, left: windowPos.x-300 } : null
				}></div>
			}

		</div>
	</div>
}

export default PlinthMenu;
