import { useEffect, useState } from "react";
import { Link } from 'react-router-dom';
import config from './../config';
import PageContent from "./elements/PageContent";

function FrontPageContent(props) {
	return <div className="inner-container">
		{
			props.data && props.data.map((section, index) => <div key={index} 
				className="frontpage-section" ref={ref => {
					if (section.ref_name) {
						props.refObj.current[section.ref_name] = ref;
					}
				}}>
				{
					section.ref_name && <a name={section.ref_name} />
				}
				<div className="text-content">
					<PageContent currentLang={props.currentLang} content={section.content} />
				</div>
			</div>)
		}
	</div>
}

export default FrontPageContent;