import { useState, useEffect } from "react";
import config from "../../config";

export default function Prices(props) {
	const [priceData, setPriceData] = useState(null);

	useEffect(() => {
		if (!priceData) {
			fetch(config.apiRoot+'prices')
				.then(res => res.json())
				.then(json => setPriceData(json.results))
		}
	}, []);

	return <div className={props.className}>

		<h2>{window.l('Aðgangseyrir')}</h2>
		{
			priceData && <table className="w-full">
				<tbody>
					{
						priceData.map((item, index) => <tr key={index}><td>{item['name_'+props.currentLang]}</td><td className="text-sam_red">{item.price > 0 ? item.price+' kr.' : window.l('Ókeypis')}</td></tr>)
					}
				</tbody>
			</table>
		}

	</div>;
}

