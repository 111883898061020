import { useRef, useState, useEffect } from 'react';
import { CircleMarker, MapContainer, Marker, Polyline, Popup, TileLayer, Tooltip, WMSTileLayer, ZoomControl } from 'react-leaflet'
import { divIcon } from 'leaflet';
import 'leaflet/dist/leaflet.css';

export default function Map(props) {
	const mapContainer = useRef(null);
	const [markers, setMarkers] = useState([]);

	useEffect(() => {
		const currentMarkersId = markers.map(marker => marker.latlng.join(',')).join(',');
		const newMarkersId = props.markers.map(marker => marker.latlng.join(',')).join(',');

		if (mapContainer.current && currentMarkersId != newMarkersId) {
			setMarkers([]);

			setTimeout(() => {
				setMarkers(props.markers);

				let bounds = props.markers.map(marker => marker.latlng);

				if (bounds.length > 0 && !props.center && !props.zoom) {
					mapContainer.current.flyToBounds(bounds, {
						duration: 1,
						padding: props.className && props.className == 'block' ? [0, 0] : [80, 80]
					});
				}
			}, 100);
		}
	}, [props.markers]);

	return <MapContainer scrollWheelZoom={false}
			zoomControl={false}
			className={props.className ? props.className : ''}
			zoom={props.zoom || 5}
			center={props.center || [64.789192,-18.267307]}
			ref={mapContainer}
			maxZoom={props.baseMap == 'iceland' ? 10 : 5}
		>
			<ZoomControl position="topright" />
			<TileLayer
				url={'/map-tiles/'+(props.baseMap || 'world')+'/{z}/{x}/{y}.png'}
				maxZoom={props.baseMap == 'iceland' ? 10 : 5}
			/>

			{
				props.drawLines && <Polyline positions={markers.map(marker => marker.latlng)} pathOptions={{
					weight: 1,
					color: '#ffffffcc'
				}} />
			}


			{
				markers.map((marker, index) => <CircleMarker radius={5} 
					stroke={0} 
					color="#fff" 
					fillColor="#fff" 
					fillOpacity="1" 
					key={index} 
					center={marker.latlng}
				>
						{/*<Popup>{marker.label}</Popup>*/}
						<Tooltip permanent={props.hideTooltips == true ? false : true}>{marker.label}</Tooltip>
					</CircleMarker>)
			}
		</MapContainer>
}

