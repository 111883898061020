import React from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import config from "./../config";

function withRouter(Component) {
	function ComponentWithRouterProp(props) {
		let location = useLocation();
		let navigate = useNavigate();
		let params = useParams();

		return (
			<Component
				{...props}
				location={location}
				history={location}
				params={params}
				navigate={navigate}
			/>
		);
	}

	return ComponentWithRouterProp;
}

class GoogleAnalytics extends React.Component {
	componentWillUpdate ({ location, history }) {
		try {
			const gtag = window.gtag;

			if (location.pathname === this.props.location.pathname) {
				// don't log identical link clicks (nav links likely)
				return;
			}

			if (history.action === 'PUSH' && typeof(gtag) === 'function') {
				let pageViewData = {
					'page_title': document.title,
					'page_location': window.location.href,
					'page_path': location.pathname
				};

				gtag('config', config.gaTrackingId, pageViewData);
			}
		}
		catch(e) {}
	}

	render () {
		return null;
	}
}

export default withRouter(GoogleAnalytics);