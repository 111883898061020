import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';

window.langData = {
	'Forsíða': 'Front Page',
	'Aðgangseyrir': 'Prices',
	'Afgreiðslutími': 'Opening Hours',
	'Til baka á forsíðu': 'Go back',
	'Sýningin': 'The Exhibition',
	'Forsíða': 'Front page',
	'Ókeypis': 'Free',

	'Heimur í orðum': 'World in Words',
	'Heimur í': 'World in',
	'orðum': 'Words',

	'Handritin': 'The Manuscripts',
	'Handritin á sýningunni': 'The Manuscripts',
	'Til sýnis í kaflanum': 'On display in the capter',

	'Handritasýning í': 'Manuscript Exhibition',
	' Eddu': ' in Edda',

	'Opið í dag': 'Open today',
	'Lokað í dag': 'Closed today',
	'Lokað til 7. janúar': 'Closed until January 7th',

	'Konungsbók Eddukvæða er til sýnis frá': 'The Codex Regius is on display from',
	'til': 'till',
	'Konungsbók Eddukvæða verður aftur til sýnis frá': 'The Codex Regius will be on display from',
	'Sjá nánar.': 'See more.',
	'Sjá myndir á': 'See images at'
};

window.l = (s) => {
	let lang;

	try {
		lang = window.localStorage.getItem('currentLang');
	}
	catch (e) {
		lang = 'is';
	}
	return lang == 'is' ? s : window.langData[s] ? window.langData[s] : s;
};

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
	<React.StrictMode>
		<BrowserRouter>
			<App />
		</BrowserRouter>
	</React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals(console.log);
