import { useRef, useState } from 'react';


export default function HotspotGenerator(props) {
	const [hotspotText, setHotspotText] = useState();
	const imageRef = useRef(null);
	const imageContainer = useRef(null);
	const [hotspots, setHotspots] = useState([]);
	const [counterStart, setCounterStart] = useState(0);

	const [hotspotInProgress, setHotspotInProgress] = useState({});

	const loadFile = (files) => {
		if (FileReader && files && files.length) {
			var fr = new FileReader();
			fr.onload = function () {
				imageRef.current.src = fr.result;
			}
			fr.readAsDataURL(files[0]);
		}
	}

	return <div className="pt-40 px-40 relative z-40">
		<div>
			<div className="flex">
				<input type="file" 
					onChange={(event) => loadFile(event.target.files)} />
				<input value={hotspotText} onChange={(event) => setHotspotText(event.target.value)} />

				<input className="ml-2" value={counterStart} onChange={(event) => setCounterStart(parseInt(event.target.value))} />
			</div>

			<div className="flex items-stretch gap-12">

				<div ref={imageContainer} className="relative basis-2/3 max-w-2/3 overflow-scroll h-[80vh]">
					<img className="max-w-[max-content]" ref={imageRef} onClick={(event) => {
						let top = (event.clientY) - imageContainer.current.offsetTop + imageContainer.current.scrollTop + window.scrollY;
						let left = (event.clientX) - imageContainer.current.offsetLeft + imageContainer.current.scrollLeft + window.scrollX;

						let currentHotspot = JSON.parse(JSON.stringify(hotspotInProgress));

						if (!currentHotspot.id) {
							currentHotspot.id = 'spot'+(hotspots.length+1+counterStart);
							currentHotspot.top = top;
							currentHotspot.left = left;

							setHotspotInProgress(currentHotspot);
						}
						else {
							currentHotspot.bottom = top;
							currentHotspot.right = left;
							currentHotspot.text = hotspotText;

							let currentHotspotList = hotspots;
							currentHotspotList.push(currentHotspot);
							
							setHotspotInProgress({});
							setHotspots(currentHotspotList);
						}
					}} />
					{
						hotspots.map((hotspot, index) => <div className="border-2 border-white absolute pointer-events-none"
							style={{
								top: hotspot.top,
								left: hotspot.left,
								height: hotspot.bottom-hotspot.top,
								width: hotspot.right-hotspot.left
							}}
						/>)
					}
				</div>

				<div className="basis-1/3 max-w-1/3">
					<pre>{JSON.stringify(hotspots, null, 2)}</pre>
				</div>

			</div>
		</div>
	</div>
}