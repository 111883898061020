import { useEffect, useState, useRef } from "react";
import { json, Link, useLocation, useParams } from 'react-router-dom';
import Image from "./elements/Image";
import config from '../config';
import _ from 'underscore';
import helpers from "../helpers";

function ManuscriptOverview(props) {
	const [manuscriptList, setManuscriptList] = useState(null);
	const [showExtraInfo, setShowExtraInfo] = useState(false);
	const [manuscriptListGrouped, setManuscriptListGrouped] = useState(null);
	const [chapters, setChapters] = useState(null);
	const [chaptersList, setChaptersList] = useState(null);
	const [selectedManuscript, setSelectedManuscript] = useState(null);
	const msDisplay = useRef(null);
	const { shelfmark } = useParams();

	useEffect(() => {
		if (!manuscriptList) {
			fetch(config.apiRoot+'manuscript_status')
				.then(res => res.json())
				.then(json => {
					if (json && json.results) {
						setManuscriptList(json.results);
						setManuscriptListGrouped(_.groupBy(json.results, (item) => item.chapter_page.chapter.id));

						setChaptersList(_.uniq(json.results.map((item) => item.chapter_page.chapter), (item) => item.id), 'id');

						setChapters((() => {
							let chapters = {};

							json.results.forEach(item => {
								if (!chapters[item.chapter_page.chapter.id]) {
									chapters[item.chapter_page.chapter.id] = item.chapter_page.chapter;
								}
							});

							return chapters;
						})());
						if (shelfmark) {
							console.log(shelfmark)
							let manuscript = _.find(json.results, (item) => item.manuscript.split(' ').join('-').replace('.', '') == shelfmark);

							setSelectedManuscript(manuscript);					
						}
					}
				});
		}
	}, []);

	useEffect(() => {
		let manuscript = _.find(manuscriptList, (item) => item.manuscript.split(' ').join('-').replace('.', '') == shelfmark);

		setShowExtraInfo(false);
		setSelectedManuscript(manuscript);
	}, [shelfmark]);

	return <div className="xl:mt-48 page-content">
		{
			selectedManuscript && <div ref={msDisplay} className="relative z-20 -mt-32 xl:-mt-[32rem] flex flex-col items-center text-sam_darkblue pb-10 mb-10 border-b-4">
				<Image className="mb-10 md:max-w-[670px] w-prose" src={helpers.mediaUrl(selectedManuscript.manuscript_image)} />
				<h2 className="!text-4xl lg:!text-5xl">{selectedManuscript.manuscript}</h2>
				{
					selectedManuscript['manuscript_name_'+props.currentLang] && <div className="text-3xl">
						{
							selectedManuscript['manuscript_name_'+props.currentLang]
						}
					</div>
				}
				{
					selectedManuscript['shortinfo_'+props.currentLang] && <p className="mt-4 !text-xl">
						{
							selectedManuscript['shortinfo_'+props.currentLang]
						}
					</p>
				}
				<div>
					<p dangerouslySetInnerHTML={{__html: selectedManuscript['info_'+props.currentLang]}} />
					{
						selectedManuscript['moreinfo_'+props.currentLang] && <p className="text-center"><button onClick={() => setShowExtraInfo(true)} className="text-sam_accent_dark">{window.l('Sjá nánar')}</button></p>
					}
					{
						selectedManuscript['moreinfo_'+props.currentLang] && <p className={'max-h-[0px] overflow-hidden transition-all'+(showExtraInfo ? ' max-h-[3000px]' : '')} dangerouslySetInnerHTML={{__html: selectedManuscript['moreinfo_'+props.currentLang]}} />
					}
					{
						selectedManuscript.handrit_is_link && 
						<div className="text-xl font-medium mb-4">{window.l('Sjá myndir á')} <a className="!text-sam_accent_dark" href={selectedManuscript.handrit_is_link}>{window.l('handrit.is')}</a></div>
					}
					<div className="text-xl font-medium mb-4">{window.l('Til sýnis í kaflanum')} <Link className="!text-sam_accent_dark" to={'/'+config.exhibitionPageRoot+'/'+selectedManuscript.chapter_page.chapter.path+'/'+selectedManuscript.chapter_page.path}>{selectedManuscript.chapter_page.chapter['title_'+props.currentLang]}</Link></div>

					<div className="flex gap-4 items-center">
						<div className="text-2xl text-sam_accent_dark" dangerouslySetInnerHTML={{__html : helpers.formatDate(selectedManuscript['date_from'], true)}} />
						<div className="grow border-b-2 border-sam_accent h-[4px] min-w-[100px]" />
						<div className="text-2xl text-right text-sam_accent_dark" dangerouslySetInnerHTML={{__html : helpers.formatDate(selectedManuscript['date_to'], true)}} />
					</div>
				</div>
			</div>
		}
		<div>
			<h2>{window.l('Handritin á sýningunni')}</h2>
			{
				chaptersList && manuscriptListGrouped && chaptersList.map((chapter) => {
					return <div key={chapter.id} className="my-20">
						<h3>{chapters[chapter.id]['title_'+props.currentLang]}</h3>
						<div className="mt-4 max-w-20 h-[4px]" style={{backgroundColor: chapters[chapter.id].theme_color}} />
						<div className="mt-8 sm:grid gap-20 grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
							{
								manuscriptListGrouped[chapter.id].map((item, index) => <Link to={'/handrit/'+item.manuscript.split(' ').join('-').replace('.', '')} onClick={() => {
										/*
										setSelectedManuscript(item);

										setTimeout(() => {
											msDisplay.current.scrollIntoView({
												behavior: 'smooth'
											});
										}, 500);
										*/
								}} className="!text-sam_darkblue text-center transition-all duration-[600ms] hover:no-underline hover:scale-105" key={index}>
									<Image className="mb-10" src={helpers.mediaUrl(item.manuscript_image)} />
									<div className="text-2xl">{item.manuscript}</div>
									{
										item['manuscript_name_'+props.currentLang] && <div>
											{
												item['manuscript_name_'+props.currentLang]
											}
										</div>
									}
								</Link>)
							}
						</div>
					</div>
				})
			}
		</div>
	</div>
}

export default ManuscriptOverview;