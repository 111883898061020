import { useRef, useState, useEffect } from 'react';
import { MapContainer, CircleMarker, Polyline, Popup, TileLayer, Tooltip, WMSTileLayer } from 'react-leaflet'
import Text from './Text';
import { divIcon } from 'leaflet';
import 'leaflet/dist/leaflet.css';

export default function TextMap(props) {
	const mapContainer = useRef(null);
	const [markers, setMarkers] = useState([]);
	const [selectedMarker, setSelectedMarker] = useState(null);

	useEffect(() => {
		const currentMarkersId = markers.map(marker => marker.latlng.join(',')).join(',');
		const newMarkersId = props.markers.map(marker => marker.latlng.join(',')).join(',');
		if (mapContainer.current && currentMarkersId != newMarkersId) {
			setMarkers(props.markers);

			let bounds = props.markers.map(marker => marker.latlng);

			if (bounds.length > 0 && !props.mapCenter && !props.zoom) {
				mapContainer.current.fitBounds(bounds, {
					animate: true,
					padding: props.className && props.className == 'block' ? [0, 0] : [80, 80],
					maxZoom: 9
				});
			}
		}
	}, [props.markers]);

	const markerIcon = divIcon({
		html: '<div style="background-color: #fff; width: 10px; height: 10px; border-radius: 10px"></div>'
	});

	let onMarkerDataHover = (marker) => {
		if (mapContainer) {
			setSelectedMarker(marker);
		}
	}
	
	return <div className="lg:flex items-stretch gap-12">
		<Text className={'basis-1/2'} 
			content={props.textContent} 
			onMarkerDataHover={onMarkerDataHover} />
		<MapContainer scrollWheelZoom={false}
			className={'basis-1/2'}
			zoom={props.zoom || 5}
			center={props.mapCenter || [64.789192,-18.267307]}
			ref={mapContainer}
			>
			<TileLayer
				url={'/map-tiles/'+(props.baseMap || 'world')+'/{z}/{x}/{y}.png'}
				maxZoom={props.baseMap == 'iceland' ? 10 : 5}
				/>

			{
				props.drawLines && <Polyline positions={markers.map(marker => marker.latlng)} pathOptions={{
					weight: 1,
					color: '#ffffffcc'
				}} />
			}

			{
				markers.map((marker, index) => <CircleMarker radius={5} 
					stroke={0} 
					color="#fff" 
					fillColor="#fff" 
					fillOpacity="1" 
					key={index} 
					center={marker.latlng}
				>
					{
						selectedMarker && marker.label == selectedMarker && 
						<Tooltip permanent={true}>{marker.label}</Tooltip>
					}
					{
						!selectedMarker && 
						<Tooltip permanent={props.hideTooltips == true ? false : true}>{marker.label}</Tooltip>
					}
				</CircleMarker>)
			}
		</MapContainer>
	</div>
}

