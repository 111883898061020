export default function Icon(props) {
	const icons = {
		'arrow-left': <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke={props.strokeColor || 'currentColor'} className={'size-6 '+props.className}>
			<path strokeLinecap="round" strokeLinejoin="round" d="M15.75 19.5 8.25 12l7.5-7.5" />
		</svg>,
		'magnifier': <svg xmlns="http://www.w3.org/2000/svg" fill="#000000" viewBox="0 0 24 24" strokeWidth={0.5} fill={props.fillColor || '#000'} strokeColor={props.strokeColor || 'currentColor'} className={'size-6 '+props.className}>
			<path d="M3.624,15a8.03,8.03,0,0,0,10.619.659l5.318,5.318a1,1,0,0,0,1.414-1.414l-5.318-5.318A8.04,8.04,0,0,0,3.624,3.624,8.042,8.042,0,0,0,3.624,15Zm1.414-9.96a6.043,6.043,0,1,1-1.77,4.274A6,6,0,0,1,5.038,5.038Z"/>
		</svg>
	}
	return icons[props.icon] || null;
}
