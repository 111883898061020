import _ from 'underscore';
import ElementGroup from "./ElementGroup";
import { useEffect, useState } from 'react';
import config from '../../config';

export default function PageContent(props) {
	const [content, setContent] = useState([]);

	const groupContent = (content) => {
		let grouped = {};

		content.forEach((item, index) => {
			if (item.elementgroup) {
				let groupOrder = _.findWhere(content, {elementgroup: item.elementgroup}).ordering;
				if (!grouped[groupOrder+'_'+item.elementgroup]) {
					grouped[groupOrder+'_'+item.elementgroup] = []
				}
				grouped[groupOrder+'_'+item.elementgroup].push(item);
			}
			else {
				grouped[(item.ordering || item.id)+'_single'] = [item]
			}
		});

		return grouped;
	}

	useEffect(() => {
		if (props.pageId) {
			fetch(config.apiRoot+(props.subPage ? 'subpage_content' : 'page_content')+'/?page='+props.pageId+'&lang='+props.currentLang)
				.then(res => res.json())
				.then(json => setContent(json.results))
		}
	}, [props.pageId, props.currentLang, props.subPage]);

	let grouped = groupContent(_.where((props.content || content), {language: props.currentLang}));

	return (props.content || content) && Object.keys(grouped).map((item, index) => <div key={index} className={'mb-12 '+(grouped[item].length > 1 ? 'lg:flex items-stretch gap-12' : '')}>
		{
			<ElementGroup groupData={grouped[item]} currentLang={props.currentLang} />
		}
	</div>)
}